<template>
  <div>
    <h2>Premium Telegram Link</h2>
    <div class="card">
      <div class="card-body">
        <form @submit.prevent="update">
          <div class="form-group">
            <label>Telegram Link</label>
            <input type="text" placeholder="Telegram Link" required v-model="telegram.link" class="form-control">
          </div>
          <div class="form-group">
            <button class="btn btn-secondary">Update</button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  mounted() {
    this.fetch()
  },

  data() {
    return {
      telegram: { link: '' }
    }
  },

  methods: {
    fetch() {
      this.$axios.get('/api/v1/moderator/telegram/1').then(response => {
        this.telegram = response.data.telegram
      })
    },

    update() {
      this.$axios.put('/api/v1/moderator/telegram/1', this.telegram).then(response => {
        this.telegram = response.data.telegram
        this.$toasted.show('Telegram link has been updated', { duration: 5000 })
      })
    }
  }
}
</script>